import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bc02e89c/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_bc02e89c/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_bc02e89c/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_bc02e89c/src/modules/globalStyleService/globals.scss");
;
import(/* webpackMode: "eager" */ "/tmp/build_bc02e89c/src/modules/imageService/placeholder.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bc02e89c/src/modules/providerService/RootProvider.tsx");
